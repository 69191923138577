<template>
    <section class="signInBg">
        <div class="nav-container" style="margin: 0 auto;">
            <div class="signHeader">
                <div class="row align-items-center">
                    <div class="col-md-4">
                        <div class="backPageBtn">
                            <button @click.prevent="$router.go(-1)">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.372 22.6">
                                        <path id="Path_1211" data-name="Path 1211" d="M848.434,81.976,858.982,93.1l9.84-11.12" transform="translate(95.316 -847.346) rotate(90)" stroke-width="3"></path>
                                    </svg>
                                </span>
                                Back
                            </button>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="pageTitleHeading">
                            <h2>SIGN UP</h2>
                        </div>
                    </div>
                    <div class="col-md-4 text-right">
                        <div class="backPageBtn">
                            <button @click.prevent="$router.push('/login')">
                                Sign In
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="signInForm">
                <div class="alert alert-danger" v-show="errorMessage" role="alert">
                    {{ errorMessage }}
                </div>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <ValidationProvider name="First Name" rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="text" v-model="form.first_name" class="form-control" placeholder="First Name*">
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="text" v-model="form.last_name" class="form-control" placeholder="Last Name*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                            <div class="formType">
                                <input type="email" v-model="form.email" class="form-control" placeholder="Email*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="password" v-model="form.password" class="form-control" placeholder="Password*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Mobile No." rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="number" v-model="form.mobile_no" class="form-control" placeholder="Mobile No*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="City" rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="text" v-model="form.city" class="form-control" placeholder="City*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="text" v-model="form.address_line_1" class="form-control" placeholder="Address Line 1*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Address 2" rules="required" v-slot="{ errors }">
                            <div class="formType">
                                <input type="text" v-model="form.address_line_2" class="form-control" placeholder="Address Line 2*" >
                                <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <div class="formSubmitBtn">
                            <div class="btnLoaderCtm" v-if="isLoading">
                                <div class="item loading-6">
                                    <svg viewBox="25 25 50 50">
                                        <circle cx="50" cy="50" r="20"></circle>
                                    </svg>
                                </div>
                            </div>
                            <button type="submit" v-else>SIGN UP</button>
                        </div>
                    </form>
                </ValidationObserver>

                <div class="otherOptionSignIn signUpBtns">
                    <!-- <button><span><img src="../../assets/images/googlelogo@2x.png" alt=""></span> Continue with Google</button>
                    <button><span><img src="../../assets/images/facebooklogo@2x.png" alt=""></span> Continue with Facebook</button>
                    <button><span><img src="../../assets/images/emailicon@2x.png" alt=""></span> Continue with Email</button> -->
                </div>
                <div class="term-service-1">
                    <p>By creating an account, you agree to our <span><a href="#">Terms of Service</a></span>
                    <br>and have read and understood the <span><a href="#">Privacy Policy.</a></span></p>
                </div>
                <div class="can-stay">
                    <p>ALREADY HAVE AN ACCOUNT? <router-link to="/login">SIGN IN</router-link></p>
                </div>
            </div>
        </div>
    </section>
    
</template>
<script>
import Swal from 'sweetalert2';
export default {
    name: 'Register',
    data: ()=>({
        form: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            mobile_no: '',
            city:'',
            address_line_1: '',
            address_line_2: '',
        },
        errorMessage: '',
        isLoading:false

    }),
    methods:{
        async onSubmit(){
            this.isLoading = true
            try{
                this.form.store_id = 1
                
            let res = await this.$axios.post('/register',this.form);

            if(res.data.request_status == 'successful'){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                this.isLoading = false;
                this.$router.push('/login');
            
                }
            }catch(error){
                
                 this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.error :   error.message,
                    });
                    
                 if(error.response.status == 400 && error.response.data.error){
                    if(error.response.data.error.email){
                         this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.email[0],
                        });
                        this.isLoading = false
                    }else{
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "Unexpected Error Occurred (Bad Request)",
                        });
                        this.isLoading =false
                    }
                    this.isLoading = false
                }            
            }
            this.isLoading = false

        }
    },
}
</script>